<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Multiselect from "vue-multiselect";

import {
	eventMethods,
} from "@/state/helpers";

/**
 * Dashboard Component
 */
export default {
  page: {
	title: "Events",
	meta: [
		{
			name: "description",
			content: appConfig.description,
		},
	],
  },
  components: {
	Layout,
	PageHeader,
    Multiselect
},
  data() {
	return {
		items: [],
		showModalHost: false,
		showModalProducts:false,
		isLoading: false,
		fullPage: true,
		canCancel: false,
		useSlot: false,
		color: "#007bff",
		bgColor: "#ffffff",
		height: 128,
		width: 128,
		lastSyncDate: '-',
		events:[],
		currentPage:1,
		perPage:9,
		rows:0,
        hostsAvailable:[],
        selectedHost:null,
        event:{},
        showModalAssignEvent:false
    }
  },
  
    mounted() {
        this.loadEvents();
    },

    created() {
        
    },

    methods: {
        ...eventMethods,

        refreshEvents(){
            this.currentPage = 1;
            this.rows = 0;
            this.loadEvents();
        },

        onShareLinkVideocall(event){
            navigator.clipboard.writeText(event.url);
        },
        
        addMinutes(numOfMinutes, date) {
            date.setTime(date.getTime() + numOfMinutes  * 60 * 1000);
            return date;
        },

        formatHourAndMinutes(event) {
            let hour = new Date(event.date).getHours();
            let minutes = new Date(event.date).getMinutes();

            if (hour.toString().length < 2) 
                hour = '0' + hour;

            if (minutes.toString().length < 2) 
                minutes = '0' + minutes;

            return hour+":"+minutes ;
        },

        getDateFormatted(event){

            var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

            let dateStr = this.$i18n.locale === 'es'? new Date(event.date).toLocaleString('es',options) : new Date(event.date).toLocaleString('en-US',options) ;
            return dateStr += "  "+this.formatHourAndMinutes(event) + ' hrs';
        },  

        onInitVideocall(event){
            window.open(event.urlHost);
        },

        onDelayVideocall(){

        },

        onCancelVideocall(event){
            let loader = this.$loading.show();

            const toUpdate = { _id: event._id,  status: "canceled", realEndDate : new Date()};
            this.updateEvent(toUpdate).then(()=>{
                this.currentPage = 1;
                loader.hide();
                this.loadEvents();

            }).catch(()=>{
                this.$notify({ type: 'error', text: this.$t("events.edit_event_error"),title:  this.$t("events.update_event") });
                loader.hide();
            })
        },

        onEndVideocall(event){
            
            let loader = this.$loading.show();

            const toUpdate = { _id: event._id,  status: "closed", realEndDate : new Date()};
            this.updateEvent(toUpdate).then(()=>{
                this.currentPage = 1;
                loader.hide();
                this.loadEvents();

            }).catch(()=>{
                this.$notify({ type: 'error', text: this.$t("events.edit_event_error"),title:  this.$t("events.update_event") });
                loader.hide();
            })
        },

        onDeriveVideocall(event){
            this.event = event;
            this.showModalAssignEvent = true;
            this.loadHostsAvailable();
        },

        onNextEventsPageClicked(){
            this.loadEvents();
        },
        loadEvents(){
            let loader = this.$loading.show();
            this.isLoading = true;
            const params={
				q: `whereObj={ "$or": [{"status":"scheduled"},{"status":"started"}]}&sortObj={"date":1}&populate[0][path]=host&populate[0][select]=_id name&populate[1][path]=campaign&populate[1][select]=name&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`,
			}

            this.getEvents(params).then((events)=>{
                if(events && events.data){
                    this.events = events.data;
                    this.rows = events.totalCount;
                }

            }).catch(()=>{
                this.$notify({ type: 'error', text: this.$t("events.get_events_error"),title:  this.$t("events.title") });
			}).finally(()=>{
                this.isLoading = false;
				loader.hide();
			});
        },

        loadHostsAvailable(){
            this.hostsAvailable = [];

            let loader = this.$loading.show();

            this.getHostsAvailable(this.event._id).then((res)=>{
                this.hostsAvailable = res.hosts;
            }).catch(()=>{
                
            }).finally(()=>{
                loader.hide();
            });
        },

        handleAssignEventClicked(){

            if(this.event && this.selectedHost){
        
                let loader = this.$loading.show();
                const payload = {
                _id: this.event._id,
                host: this.selectedHost._id
                }
                
                this.updateEvent(payload).then((result)=>{
                    if(!result.data)
                        throw new Error();

                    this.loadEvents();
                    this.showModalAssignEvent = false;
                    this.event = null;
                    this.selectedHost=null;
          
                }).catch(()=>{
                    this.$notify({ type: 'error', text: this.$t("events.edit_event_error"),title:  this.$t("events.update_event") });
                }).finally(()=>{
                    loader.hide();
                });
            }else{
                this.showModalAssignEvent = false;
            }
        }
    },

};
</script>

<template>
	<Layout>
		<PageHeader :title="$t('next_events.title')" :items="items" />    
        <div class="row">
            
                <div class="text-sm-end">
                    <button
                        type="button"
                        class="btn btn-primary btn-rounded mb-2 me-2"
                        style="float:right"
                        @click="refreshEvents">
                    <i class="mdi mdi-refresh me-1"></i> {{$t('next_events.refresh')}}
                </button>
                </div>
                <div class="text-center empty-list col-xl-12" v-if="!isLoading && events.length == 0">
                    <span> {{ $t("next_events.empty_list") }} </span>
                    <div> <i class="bx bx-video me-1 text-muted display-4"/> </div>
                </div>
            <div class="col-xl-4 col-sm-6" v-for="event in events" :key="event._id">
                <div class="card" v-if="!event.type">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-12">
                                <div>
                                    <div class="top-right-menu">
                                        <span class="badge font-size-10 bg-primary" v-if="event.status == 'started'">{{$t('next_events.status_started')}}</span>
                                        <b-dropdown
                                            class="card-drop"
                                            variant="white"
                                            right
                                            toggle-class="p-0"
                                            menu-class="dropdown-menu-end">
                                            <template v-slot:button-content>
                                            <i class="mdi mdi-dots-vertical font-size-18"></i>
                                            </template>

                                            <b-dropdown-item @click="onInitVideocall(event)" v-if="event.status == 'scheduled'">
                                                <i class="fas fa-video text-primary me-1"></i>
                                                {{$t('next_events.start')}}
                                            </b-dropdown-item>

                                            <b-dropdown-item @click="onDeriveVideocall(event)" v-if="!event.type && event.status == 'scheduled'">
                                                <i class="fas fa-user text-success me-1"></i>
                                                {{$t('next_events.derive')}}
                                            </b-dropdown-item>

                                            <b-dropdown-item @click="onInitVideocall(event)" v-if="event.status == 'started'">
                                                <i class="fas fa-video text-primary me-1"></i>
                                                {{$t('next_events.continue')}}
                                            </b-dropdown-item>

                                            <b-dropdown-item @click="onEndVideocall(event)" v-if="event.status == 'started'">
                                                <i class="fas fa-stop-circle text-primary me-1"></i>
                                                {{$t('next_events.end')}}
                                            </b-dropdown-item>

                                            <b-dropdown-item @click="onShareLinkVideocall(event)" v-if=" !event.type && (event.status == 'scheduled' || event.status == 'started')">
                                                <i class="fas fa-share text-primary me-1"></i>
                                                {{$t('common.share')}}
                                            </b-dropdown-item>
                                            
                                            <b-dropdown-item @click="onDelayVideocall(event)" v-if="!event.type && event.status == 'scheduled'">
                                                <i class="fas fa-clock text-warning me-1"></i>
                                                {{$t('next_events.delay')}}
                                            </b-dropdown-item>
                                            <b-dropdown-item @click="onCancelVideocall(event)" v-if=" !event.type && event.status == 'scheduled'">
                                                <i class="fas fa-trash-alt text-danger me-1"></i>
                                                {{$t('next_events.cancel')}}
                                            </b-dropdown-item>
                                            
                                        </b-dropdown>
                                    </div>
                                    <p class="d-block text-primary text-decoration-underline mb-2">{{event.campaign.name}}</p>
                                    <h5 class="text-truncate mb-4 mb-lg-5">{{event.title}}</h5>
                                    <ul class="list-inline mb-0">
                                        <li >
                                            <h5 class="font-size-12">
                                                <i class="bx bx-calendar me-1 text-muted"></i> {{ getDateFormatted(event) }}
                                            </h5>
                                        </li>

                                        <li v-if="event.invites && event.invites.length > 0">
                                            <h5 class="font-size-12">
                                                <i class="bx bx-user me-1 text-muted"></i> {{event.invites[0].name}}
                                            </h5>
                                        </li>

                                        <li v-if="event.invites && event.invites.length > 0">
                                            <h5 class="font-size-12">
                                                <i class="bx bx-envelope me-1 text-muted"></i> {{event.invites[0].email}}
                                            </h5>
                                        </li>

                                        <li v-if="event.invites && event.invites.length > 0">
                                            <h5 class="font-size-12">
                                                <i class="bx bx-phone me-1 text-muted"></i> {{event.invites[0].tel || ''}}
                                            </h5>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card bg-primary bg-soft" v-if="event.type && event.type == 'ONE_TO_MANY'">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-12">
                                <div>
                                    <div class="top-right-menu">
                                        <span class="badge font-size-10 bg-primary" v-if="event.status == 'started'">{{$t('next_events.status_started')}}</span>
                                        <b-dropdown
                                            class="card-drop"
                                            variant="white"
                                            right
                                            toggle-class="p-0"
                                            menu-class="dropdown-menu-end">
                                            <template v-slot:button-content>
                                            <i class="mdi mdi-dots-vertical font-size-18"></i>
                                            </template>

                                            <b-dropdown-item @click="onInitVideocall(event)" v-if="event.status == 'scheduled'">
                                                <i class="fas fa-video text-primary me-1"></i>
                                                {{$t("events.start_show")}}
                                            </b-dropdown-item>

                                            <b-dropdown-item @click="onInitVideocall(event)" v-if="event.status == 'started'">
                                                <i class="fas fa-video text-primary me-1"></i>
                                                {{$t('next_events.continue')}}
                                            </b-dropdown-item>

                                            <b-dropdown-item @click="onEndVideocall(event)" v-if="event.status == 'started'">
                                                <i class="fas fa-stop-circle text-primary me-1"></i>
                                                {{$t('next_events.end')}}
                                            </b-dropdown-item>
                                        </b-dropdown>
                                    </div>
                                    <h4 class="text-truncate mb-4 mb-lg-5 text-primary">{{event.title}}</h4>
                                    <p class="d-block text-primary text-decoration-underline mb-2">{{event.campaign.name}}</p>
                                    <ul class="list-inline mb-0">
                                        <li >
                                            <h5 class="font-size-12">
                                                <i class="bx bx-calendar me-1 text-muted"></i> {{ getDateFormatted(event) }}
                                            </h5>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <div class="row mb-4" v-if="rows > perPage">
                <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-end">
                        <ul class="pagination pagination-rounded mb-0">
                            <b-pagination
                                @input="onNextEventsPageClicked"
                                v-model="currentPage"
                                :total-rows="rows"
                                :per-page="perPage">
                            </b-pagination>
                        </ul>
                    </div>
                </div>
            </div>
		</div>
        <b-modal
            :title="event.title"
            v-model="showModalAssignEvent"
            title-class="text-black font-18"
            body-class="p-3"
            hide-footer>
            <div>
                <p>{{$t("events.events_available_hosts") }}</p>

                <div class="col-8 mb-3 text-end">
                <multiselect 
                    v-model="selectedHost"
                    :options="hostsAvailable"
                    :selectLabel="$t('common.select')"
                    :deselectLabel="$t('common.deselect')"
                    :placeholder="$t('events.events_available_host_placeholder')"
                    :multiple="false"
                    track-by="_id" 
                    label="name"
                    :showNoResults="true"
                    open-direction="bottom">
                </multiselect>
                </div>

                <div class="text-end pt-2 mt-3">
                <b-button variant="primary" class="ms-1" @click="handleAssignEventClicked">{{$t('common.accept')}}</b-button>
                </div>
            </div>
            </b-modal>	
	</Layout>
</template>

<style scoped>
.interact{
	cursor: pointer;
  }

.bold{
	font-weight: bold;
}

.top-right-menu {
    cursor: pointer;
    position: absolute;
    top: -10px;
    right: 0px;
}

.empty-list {
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>